<script setup lang="ts">
import { WsButton } from '@mfl/common-components';
import { headerMode, HeaderMode } from '@mfe/core-header';
import { computed } from 'vue';

const headerActionVerb = computed(() =>
  headerMode.value === HeaderMode.Hidden ? "Now you don't" : 'Now you see me'
);

function toggleHeader() {
  headerMode.value =
    headerMode.value === HeaderMode.Hidden
      ? HeaderMode.Shown
      : HeaderMode.Hidden;
}
</script>

<template>
  <div class="header-tester">
    <h3><b>Header tester</b></h3>
    <WsButton
      :label="headerActionVerb"
      aid="SHOW_HIDE_HEADER"
      @click="toggleHeader()"
    />
  </div>
</template>

<style lang="css" scoped>
.header-tester {
  align-self: center;
  border: 1px dotted black;
  padding: 8px;
  display: flex;
  flex-direction: column;
}
</style>
