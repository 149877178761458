<script setup lang="ts">
import logo from './assets/local-dev-logo.jpg';
const logoUrl = import.meta.resolve(
  import.meta.env.BASE_URL + 'local-dev-logo.jpg'
);
</script>

<template>
  <div class="navigation-tester">
    <h3><b>Image sample</b></h3>
    Three ways to load images (and other assets)<br /><br />
    <div class="local-dev-logo">
      inline image (data URI)<br />
      <code> import logo from './assets/local-dev-logo.jpg'; </code>
      image is bundled within the js file
      <img alt="local dev companion" :src="logo" />
    </div>

    <div class="local-dev-logo">
      image from the public folder (uri resolved by VITE)<br />

      <code> &lt;img src=&quot;/local-dev-logo.jpg&quot;/&gt; </code>
      image is "uploaded to cdn" during deploy<br />
      (./public folder on the root level of the package)
      <img
        class="local-dev-logo"
        alt="local dev companion from CDN"
        src="/local-dev-logo.jpg"
      />
    </div>

    <div class="local-dev-logo">
      image from the public folder (uri resolved programmatically)<br />
      <code>
        import.meta.resolve(import.meta.env.BASE_URL + 'local-dev-logo.jpg')
      </code>
      same as above but with JS (check your design if you got here)
      <img
        class="local-dev-logo"
        alt="local dev companion from CDN"
        :src="logoUrl"
      />
    </div>
  </div>
</template>

<style lang="css" scoped>
.navigation-tester {
  max-width: 90%;
  align-self: center;
  border: 1px dotted black;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.local-dev-logo {
  /* align-self: center; */
  display: flex;
  flex-direction: column;
  img {
    align-self: center;

    /* align-self: flex-end; */
    /* margin-left: auto; */
    width: 150px;
    height: 150px;
  }
}
</style>
