import { onMounted, onUnmounted } from 'vue';
import { Priority, Unsub } from '@mfl/framework';
import { prompt } from '@mfl/common-components';
import { type NavigationItem, navigationItems } from '@mfe/core-navigation';

import employeesSvg from './assets/users.svg';
import employeesActiveSvg from './assets/users-active.svg';

export function useNavBarHierarchy() {
  const unsubs: Unsub[] = [];
  onMounted(() => {
    function reg(item: NavigationItem, priority: Priority) {
      unsubs.push(navigationItems.register(item, { priority }));
    }

    firstHierarchy();
    secondHierarchy();

    function firstHierarchy() {
      reg(
        {
          key: 'ICON-URL-PARENT',
          text: 'Users parent item',
          iconUrl: employeesSvg,
          iconUrlActive: employeesActiveSvg,
        },
        Priority.Low - 4000
      );

      reg(
        {
          parentKey: 'ICON-URL-PARENT',
          key: 'ICON-URL-CHILD-1',
          text: 'Users child item#1',
          route: '/v1/dev/local/parent1/child1',
          iconUrl: employeesSvg,
          iconUrlActive: employeesActiveSvg,
        },
        Priority.Low - 4000
      );

      reg(
        {
          parentKey: 'ICON-URL-PARENT',
          key: 'ICON-URL-CHILD-2',
          text: 'User child item#2',
          route: '/v1/dev/local/icon-url-parent',
          fontAwesomeIcon: 'xmark',
        },
        Priority.Low - 5000
      );

      reg(
        {
          parentKey: 'ICON-URL-CHILD-1',
          key: 'ICON-URL-SUB-CHILD-1',
          text: 'User subchild item#1',
          route: '/v1/dev/local/icon-url-parent/child-1',
          iconUrl: employeesSvg,
          iconUrlActive: employeesActiveSvg,
        },
        Priority.Low - 4001
      );

      reg(
        {
          parentKey: 'ICON-URL-CHILD-1',
          key: 'ICON-URL-SUB-CHILD-2',
          text: 'User subchild item#2',
          route: '/v1/dev/local/icon-url-parent/child-2',
          iconUrl: employeesSvg,
          iconUrlActive: employeesActiveSvg,
        },
        Priority.Low - 4002
      );

      reg(
        {
          key: 'CHILD2',
          text: 'Child 2',
          route: '/v1/dev/local/child2',
          fontAwesomeIcon: 'xmark',
          parentKey: 'PARENT',
          ornaments: [{ type: 'beta' }, { type: 'pro' }],
        },
        Priority.Medium
      );

      reg(
        {
          key: 'CHILD3',
          text: 'Child 3',
          route: '/v1/dev/local/child2/child3',
          fontAwesomeIcon: 'xmark',
          parentKey: 'PARENT',
          handler: async (_item: NavigationItem): Promise<boolean> => {
            await prompt({
              aid: 'A_PROMPT_FROM_NAV_BAR',
              header: 'Upgrade prompt',
              statement:
                'This navigation menu item does not have a route associated with it, this pattern is usually used to upsale pro features',
              question: 'Do you like prompts?',
              primaryButtonText: "Who doesn't?!?",
              secondaryButtonText: 'Sure do!',
            });
            return false; // tell navbar to skip navigation
          },
          ornaments: [{ type: 'pro' }],
        },
        Priority.Low
      );

      // Proof that order does not matter, only priority
      reg(
        {
          key: 'CHILD1',
          text: 'Child 1',
          route: '/v1/dev/local/child1',
          fontAwesomeIcon: 'xmark',
          parentKey: 'PARENT',
          ornaments: [{ type: 'beta' }],
        },
        Priority.High
      );

      reg(
        {
          key: 'CHILD2.1',
          text: 'Child 2.1',
          route: '/v1/dev/local/child2-1',
          fontAwesomeIcon: 'xmark',
          parentKey: 'CHILD2',
          ornaments: [{ type: 'pro' }],
        },
        Priority.High + 10000
      );

      // Adding a duplicate PARENT item
      // It should not be displayed and there should be no console error
      reg(
        {
          key: 'PARENT',
          text: 'A parent item (local-dev)',
          fontAwesomeIcon: 'xmark',
        },
        Priority.Low - 5000
      );
    }

    function secondHierarchy() {
      reg(
        {
          key: 'PARENT2',
          text: 'dev test 2',
          fontAwesomeIcon: 'xmark',
        },
        Priority.Low - 5005
      );

      reg(
        {
          key: '2CHILD1',
          text: 'Child 1',
          route: '/v1/dev/local/2/child1',
          fontAwesomeIcon: 'xmark',
          parentKey: 'PARENT2',
        },
        Priority.Medium
      );

      reg(
        {
          key: '2CHILD2',
          text: 'Child 2',
          route: '/v1/dev/local/2/child2',
          fontAwesomeIcon: 'xmark',
          parentKey: 'PARENT2',
        },
        Priority.Low
      );
    }
  });

  onUnmounted(() => {
    unsubs.forEach((unsub) => unsub());
  });
}
